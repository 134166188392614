import { useState } from 'react';
import { createStyles, Navbar, Group, Code, Image, useMantineTheme, Text } from '@mantine/core';
import {
  IconHistory, IconTriangleInverted,
} from '@tabler/icons';
import { Link, useLocation } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';

const useStyles = createStyles((theme, _params, getRef) => {
  const icon: any = getRef('icon');
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
    },


    linkIcon: {
      ref: icon,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
          .background,
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        [`& .${icon}`]: {
          color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        },
      },
    },

    themeToggle: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
    },
  };
});

const data = [
  { link: 'transactions', label: 'Transaction History', icon: IconHistory },
  // { link: 'billing', label: 'Billing', icon: IconReceipt2 },
  // { link: 'apikeys', label: 'API Keys', icon: IconKey },
  // { link: 'chains', label: 'Chains', icon: IconDna },
];

export function DashboardNav() {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  const { pathname } = useLocation();

  const links = data.map((item) => {
    return(
      <Link
        className={cx(classes.link, { [classes.linkActive]: pathname.includes(item.link) })}
        to={item.link}
        key={item.label}
      >
        <item.icon className={classes.linkIcon} stroke={1.5} /><span>{item.label}</span>
      </Link>
    );
  });

  return (
    <Navbar width={{ sm: 300 }} style={{position: 'fixed'}} p="md">
      <Navbar.Section grow style={{display: 'flex', flexDirection: 'column'}}>
        <Group className={classes.header} position="apart">
          <Group spacing="sm">
            <Image
              src="Trivium_logo_white_opt.svg"
              alt={'logo'}
              height={30}
              width={'auto'}
              style={{ filter: theme.colorScheme === 'light' ? 'invert(99%) sepia(74%) saturate(422%) hue-rotate(202deg) brightness(117%) contrast(100%)' : undefined }}
            />
            <Text weight={500}>Trivium Tools</Text>
          </Group>
          <Code sx={{ fontWeight: 700 }}>Alpha</Code>
        </Group>
        {links}
        <a href="https://trivium.network" target="_blank" rel="noreferrer noopener" className={classes.link} style={{marginTop: 'auto'}}>
          <IconTriangleInverted className={classes.linkIcon} stroke={1.5} />
          <span>By Trivium Node</span>
        </a>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        

        <div className={classes.themeToggle}><Text>Theme</Text><ThemeToggle my={0}/></div>

        {/* <a href="#" className={classes.link} onClick={(event) => event.preventDefault()}> */}
      </Navbar.Section>
    </Navbar>
  );
}