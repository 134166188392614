import { Accordion, useMantineTheme, Text, Group } from '@mantine/core';
import { ReactChild } from 'react';
import ReactJson from 'react-json-view';
import { TxResponse } from 'secretjs';
import { json } from 'stream/consumers';

interface RowProps {
    tx: TxResponse;
    txType: ReactChild;
    app: ReactChild;
    from: ReactChild;
    fromAmount: ReactChild;
    to: ReactChild;
    toAmount: ReactChild;
    showFullTx?: boolean;
  }

export const TxRow = ({
  tx,
  txType,
  app,
  from,
  fromAmount,
  to,
  toAmount,
  showFullTx
}: RowProps) => {  
  
  const theme = useMantineTheme();
  
  return (
    <>    <Text size="xs" style={{position: 'absolute', padding: '5px'}}><a href={`https://secretnodes.com/secret/transactions/${tx.transactionHash}`} target="_blank" rel="noreferrer noopener">{tx.transactionHash}</a></Text>

      <tr style={{height: 100}} /*style={tx.code ? {backgroundColor: theme.colorScheme === 'dark' ? '#ff534aaa' : '#FF6961'} : undefined}*/ key={tx.transactionHash}>
        <td>
          <Text size="sm" weight={500}>
            {tx.height}
          </Text>
          <Text size="sm" weight={500}>
            {tx.timestamp}
          </Text>
          {!!tx.code &&
            <Text color='red'>
              Failed
            </Text>
          }
        </td>
        <td>{txType}</td>
        <td>{app}</td>
        <td>{from}</td>
        <td>{fromAmount}</td>
        <td>
          {to}
          {!!tx.code && <Text color='red'>
            {JSON.stringify(tx.jsonLog)}
          </Text>
          }
          {!!showFullTx &&
          <Accordion.Item value={tx.transactionHash}>
            <Accordion.Control>
                <Text size="sm" align='right'>View</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <ReactJson theme="shapeshifter" indentWidth={2} collapsed={1} collapseStringsAfterLength={64} name='Full TX JSON' src={tx} />
            </Accordion.Panel>
          </Accordion.Item>
          }
        </td>
        <td>{toAmount}</td>
        {/* <td><a href={`https://secretnodes.com/secret/transactions/${tx.transactionHash}`} target="_blank" rel="noreferrer noopener">View</a></td> */}
      </tr></>
  );
};