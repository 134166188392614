import {
  createContext,
  useState,
  useContext,
  ReactElement,
  ReactNode,
  useEffect
} from 'react';

export interface UserContextState {
  accessToken?: string;
}

interface Props {
  children: ReactNode;
}

// created context with no default values
const UserContext = createContext<UserContextState>({});

export const UserProvider = ({ children }: Props): ReactElement => {
  const [accessToken, setAccessToken] = useState<string>();

  const values = {
    accessToken
  };

  // add values to provider to reach them out from another component
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// created custom hook
export const useUser = (): UserContextState => useContext(UserContext);
