import { ActionIcon, useMantineColorScheme, useMantineTheme, Switch, Group } from '@mantine/core';
import { IconSun, IconMoonStars } from '@tabler/icons';

export default function ThemeToggle({my=30}: {my?: number}) {
  const theme = useMantineTheme();
  const { toggleColorScheme } = useMantineColorScheme();
  const dark = theme.colorScheme === 'dark';

  // return (
  //   <ActionIcon
  //     variant="outline"
  //     color={dark ? 'yellow' : 'blue'}
  //     onClick={() => toggleColorScheme()}
  //     title="Toggle color scheme"
  //   >
  //     {dark ? <IconSun size={18} /> : <IconMoonStars size={18} />}
  //   </ActionIcon>
  // );

  return (
    <Group position="center" my={my}>
      <Switch
        checked={!dark}
        onChange={() => toggleColorScheme()}
        size="lg"
        onLabel={<IconSun color={theme.white} size={20} stroke={1.5} />}
        offLabel={<IconMoonStars color={theme.colors.gray[6]} size={20} stroke={1.5} />}
      />
    </Group>
  );
}