import { Container, Title, SimpleGrid, createStyles, Loader, Text } from '@mantine/core';
import { DashNotif } from '../Components/Notification';
import { SecretNetworkClient, TxResponse } from 'secretjs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { TXTable } from '../Components/TXTable';

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: '1vh 1vw',
    marginLeft: '300px',
  },
      
  item: {
    display: 'flex',
  },
      
  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md,
  },
      
  itemTitle: {
    marginBottom: theme.spacing.xs / 2,
  },
      
  supTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    letterSpacing: 0.5,
  },
      
  title: {
    lineHeight: 1,
    textAlign: 'center',
  },
      
  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xs,
  },
      
  highlight: {
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    padding: 5,
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: 'inline-block',
    color: theme.colorScheme === 'dark' ? theme.white : 'inherit',
  },
}));

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));


export default function TransactionsPage() {
  const { classes } = useStyles();
  const [transactions, setTransactions] = useState<TxResponse[]>([]);
  
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  
  const [address, setAddress] = useState<string>();

  useEffect(()=>{
    getData();
  },[]);

  const getData = async() => {
    setLoadingTransactions(true);
    try {
      while (
        !window.keplr ||
        !window.getEnigmaUtils ||
        !window.getOfflineSignerOnlyAmino
      ) {
        await sleep(50);
      }
      const CHAIN_ID = 'secret-4';
    
      await window.keplr.enable(CHAIN_ID);
      const keplrOfflineSigner = window.keplr.getOfflineSignerOnlyAmino(CHAIN_ID);
      const [{ address: myAddress }] = await keplrOfflineSigner.getAccounts();
    
      setAddress(myAddress);

      console.log(process.env.REACT_APP_LCD_URL);
      const secretjs = new SecretNetworkClient({
        url: process.env.REACT_APP_LCD_URL as string,
        chainId: CHAIN_ID,
        wallet: keplrOfflineSigner,
        walletAddress: myAddress,
        encryptionUtils: window.keplr.getEnigmaUtils(CHAIN_ID),
      });

      const conditions = [
        `transfer.sender='${myAddress}'`,
        `transfer.recipient='${myAddress}'`,
        `message.action='/secret.compute.v1beta1.MsgExecuteContract' AND message.sender='${myAddress}'`
      ]

      const allResults = [];
      for (let i = 0; i < conditions.length; i++){
        const data = await secretjs.query.txsQuery(conditions[i]);

        allResults.push(...data);
      }

      const unique = [...new Map(allResults.map((tx) => [tx.transactionHash, tx])).values()];
      unique.sort((a,b) => b.height - a.height);

      console.log(unique);
      setTransactions(unique);

    } catch(e: any) {
      toast.error(e.toString());
      console.error(e);
    } finally {
      setLoadingTransactions(false);
    }
  };


  return (
    <Container className={classes.wrapper} fluid={true}>
      <Title className={classes.title}>
        Transaction History
      </Title>
      <Text weight={500} color='red' align='center'>
        Alpha Software
      </Text>
      <Text align='center' mb={15}>
        This tool has not been thoroughly tested. Accuracy is not guaranteed.<br />Some transactions may not show, use this tool in combination with explorers and other tools for the most accurate results.
      </Text>
      {!!(loadingTransactions && transactions.length) && <div style={{display: 'flex', justifyContent: 'center'}}><Loader size={50} /></div>}

      <TXTable data={transactions} address={address as string} />
      {!!(loadingTransactions && !transactions.length) && <div style={{display: 'flex', justifyContent: 'center'}}><Loader size={50} /></div>}
    </Container>
  );
}

