import { Accordion, Group, PasswordInput, Text } from '@mantine/core';
import { useState, useEffect } from 'react';
import { Coin, MsgExecuteContract, MsgSnip20Send, TxResponse } from 'secretjs';
import { Snip20SendOptions } from 'secretjs/dist/extensions/snip20/types';
import { ContractInfo } from 'secretjs/dist/grpc_gateway/secret/compute/v1beta1/types.pb';
import { CLOAK_ADDRESS, CLOAK_OPERATOR } from '../../Data/config';
import { findOtherToken, findSecretswapPair, findSecretSwapPool, INFINITY_POOL, INFINITY_POOL_CONTRACT, SECRETSWAP_ROUTER_CONTRACT } from '../../Data/secretswap';
import { findLog, findWasmLog, getContractInfo, getExchangeRate, getSiennaPairInfo, getTokenInfo, querierJs } from '../../Utils/querier';
import ReactJson from 'react-json-view';
import { TxRow } from './Row';
import HiddenText from '../HiddenText';

interface RowProps {
    tx: TxResponse;
    address: string;
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
const ComputeRow = ({tx, address}: RowProps) => {
  const [contractInfo, setContractInfo] = useState<ContractInfo>();
  const [txType, setTxType] = useState('Execute');
  const [txApp, setTxApp] = useState('');
  const [from, setFrom] = useState<any>();
  const [to, setTo] = useState<any>();
  const [frAmount, setFrAmount] = useState<any>('');
  const [toAmount, setToAmount] = useState<any>('');
  const [hideJson, setHideJson] = useState(false);
    
  //@ts-ignore
  const txMsg: any = tx.tx.body?.messages[0];
    
  //@ts-ignore
  const executedContract = txMsg.contract;
  const executedHandle = Object.keys(txMsg.msg)[0];
  
  const fetchContractInfo = async() => {
    const ContractInfo = await getContractInfo(executedContract);
    setContractInfo(ContractInfo);
  };
  
  useEffect(()=>{
    fetchContractInfo();
  },[]);
  
  useEffect(()=>{
    if (executedContract === CLOAK_ADDRESS && txMsg.sender === CLOAK_OPERATOR) {
      handleCloakReceive();
      return;
    }
    const handle = Object.keys(txMsg.msg)[0];

    setTo(<>
      <Text size="sm" weight={500}>
        {contractInfo?.label}: {handle}
      </Text>
      <Text size="sm" weight={500}>
        {executedContract}
      </Text>
    </>);

    if (tx.code) return;

    switch(handle){
      case 'set_viewing_key':
        handleSetViewingKey();
        return;
      case 'send':
        if (txMsg.msg.send.amount){
          handleSnip20Send(txMsg.msg.send);
          return;
        }
        break;
      case 'transfer':
        if (txMsg.msg.transfer.amount){
          handleSnip20Send(txMsg.msg.transfer);
          return;
        }
        break;
      case 'deposit':
        handleSnip20Deposit();
        return;
        break;
      default:
        break;
    }

    // no match above
    const secretswapPair = findSecretswapPair(executedContract);
    if (secretswapPair) {
      handleSecretswapOther(secretswapPair);
      return;
    }

    const secretswapPool = findSecretSwapPool(executedContract);
    if (secretswapPool || executedContract === INFINITY_POOL_CONTRACT) {
      handleSecretswapPool(secretswapPool || INFINITY_POOL, txMsg.msg);
      return;
    }
    
  },[contractInfo]);

  const handleSetViewingKey = async() => {
    setTxType('Execute');
    setTo(<>
      <Text size="sm" weight={500}>
      Set Viewing Key - {contractInfo?.label}
      </Text>
      <Text size="sm" weight={400}>
        <div style={{display: 'flex'}}>
          Key:&nbsp;&nbsp;&nbsp;&nbsp;<HiddenText text={txMsg.msg.set_viewing_key.key} />
        </div>
      </Text>
      <Text size="sm" weight={400}>
        Contract: {executedContract}
      </Text>
    </>)
  };

  const handleCloakReceive = async() => {
    const amount = findLog(tx.arrayLog, 'transfer', 'amount')?.replace('uscrt','');
    if (!amount) throw `Unable to find seed amount for cloak receive at ${tx.height}`;
    const toString = parseInt(amount) / Math.pow(10,6) + ' SCRT';
    setTxType('Send');
    setTxApp('BlackBox');
    setFrom(<>
      <Text size="sm" weight={500}>
        BlackBox Cloak
      </Text>
      <Text size="sm" weight={400}>
          Recieve Seed
      </Text>
    </>);
    setToAmount(toString);
    setTo(<>
      <Text size="sm" weight={500}>
        You
      </Text>
    </>);
    setHideJson(true);
  };
  
  const handleSnip20Send = async(data: any) => {
    const token_info = await getTokenInfo(executedContract);

    const fromString = data.amount / Math.pow(10,token_info.decimals) + ` ${token_info.symbol}`;

    let destContractInfo;
    if (data.msg) {
      setTxType('Execute');
      const handleMsg = JSON.parse(atob(data.msg));
      const handle = Object.keys(handleMsg)[0];

      if (data.recipient === CLOAK_ADDRESS) {
        switch(handle){
          case 'receive_seed':
            if (data.recipient !== CLOAK_ADDRESS) break;
            handleCloakDeposit();
            return;
          default:
            break;
        }
      }
      
      const secretswapPair = findSecretswapPair(data.recipient);
      if (secretswapPair || data.recipient === SECRETSWAP_ROUTER_CONTRACT) {
        handleSecretswapSend(handleMsg);
        return;
      }

      const secretswapPool = findSecretSwapPool(data.recipient);
      if (secretswapPool || data.recipient === INFINITY_POOL_CONTRACT) {
        handleSecretswapPool(secretswapPool || INFINITY_POOL, handleMsg);
        return;
      }

      const { ContractInfo } = await querierJs.query.compute.contractInfo({
        contract_address: data.recipient,
      });
      destContractInfo = ContractInfo;

      if (destContractInfo?.code_id === '361') {
        handleSendToSienna(handleMsg);
        return;
      }

      setTo(<>
        <Text size="sm" weight={500}>
          {destContractInfo?.label}: {handle}
        </Text>
        <Text size="sm" weight={500}>
          {data.recipient}
        </Text>
      </>);

      //todo, check logs to see if this actually hit the contracts recieve handle
    } else {
      setTo(data.recipient === address ? 'You' : data.recipient);
      setHideJson(true);
    }
      
    setTxType(data.msg ? 'Execute' : 'Send');
    setFrom('You');
    setFrAmount(fromString.toString());
  };

  const handleSendToSienna = async(handleMsg: any) => {
    setTxApp('Sienna');
    const {send: sendMsg}: Snip20SendOptions = txMsg.msg;

    const from_token = executedContract;
    const from_token_info = await getTokenInfo(executedContract);
    const from_token_amount = `${parseInt(sendMsg.amount) / Math.pow(10,from_token_info.decimals)} ${from_token_info.symbol}`;

    const handle = Object.keys(handleMsg)[0];
    switch(handle){
      case 'swap': {
        setTxType('Swap');
        setFrAmount(from_token_amount);

        const swap_contract = sendMsg.recipient;
        const pair_info = await getSiennaPairInfo(swap_contract);

        const otherToken = pair_info.pair.token_0.custom_token.contract_addr === from_token ? pair_info.pair.token_1.custom_token.contract_addr : pair_info.pair.token_0.custom_token.contract_addr;
        const to_token_info = await getTokenInfo(otherToken);

        setTo(<>
          <Text size="sm" weight={500}>
            SiennaSwap Pair&nbsp;&nbsp;&nbsp;&nbsp;{`${from_token_info.symbol} <---> ${to_token_info.symbol}`}
          </Text>
          <Text size="sm" weight={300}>
            {`Swap Contract: ${sendMsg.recipient}`}
          </Text>
          <Text size="sm" weight={300}>
            {`${from_token_info.symbol} Contract: ${executedContract}`}
          </Text>
          <Text size="sm" weight={300}>
            {`${to_token_info.symbol} Contract: ${handleMsg.swap.to}`}
          </Text>
        </>);

        const estAmountStr = `~${parseInt(handleMsg.swap.expected_return) / Math.pow(10,to_token_info.decimals)} ${to_token_info.symbol}`;
        const exactAmount = (tx.arrayLog || []).find(log=> log.type==='wasm' && log.key==='return_amount')?.value;

        if (exactAmount) {
          setToAmount(`${parseInt(exactAmount) / Math.pow(10,to_token_info.decimals)} ${to_token_info.symbol}`);
        } else {
          setToAmount(<>
            <Text size="sm" weight={500}>
              {estAmountStr}
            </Text>
            <Text size="xs" weight={300}>
            Minimum Return
            </Text>
          </>);
        }

        return;
      }
      case 'to': {
        // Router swap
        setTxType('Swap');
        setFrAmount(from_token_amount);

        let token = from_token;
        const history = [from_token_info.symbol];

        for (let i=0; i < handleMsg.hops.length; i++){
          const hop = handleMsg.hops[i];
          const otherToken = findOtherToken(hop.pair_address, token);
          token = otherToken?.token?.contract_addr;

          const token_info = await getTokenInfo(token);
          history.push(token_info.symbol);
        }

        if (!token || token === from_token) throw `Failed to find destination token of router swap at ${tx.height}`;
        const to_token_info = await getTokenInfo(token);

        setTo(<>
          <Text size="sm" weight={500}>
            SecretSwap Router Swap&nbsp;&nbsp;&nbsp;&nbsp;{`${history.join(' --> ')}`}
          </Text>
          <Text size="sm" weight={300}>
            {`Swap Contract: ${sendMsg.recipient}`}
          </Text>
          <Text size="sm" weight={300}>
            {`${from_token_info.symbol} Contract: ${executedContract}`}
          </Text>
          <Text size="sm" weight={300}>
            {`${to_token_info.symbol} Contract: ${token}`}
          </Text>
        </>);

        const estAmountStr = `~${parseInt(handleMsg.expected_return) / Math.pow(10,to_token_info.decimals)} ${to_token_info.symbol}`;

        setToAmount(<>
          <Text size="sm" weight={500}>
            {estAmountStr}
          </Text>
          <Text size="xs" weight={300}>
            Minimum Return
          </Text>
        </>);
        // const exactAmount = (tx.arrayLog || []).find(log=> log.type==='wasm' && log.key==='return_amount')?.value;

        // if (exactAmount) {
        //   setToAmount(`${parseInt(exactAmount) / Math.pow(10,to_token_info.decimals)} ${to_token_info.symbol}`);
        // } else {
        //   setToAmount(<>
        //     <Text size="sm" weight={500}>
        //       {estAmountStr}
        //     </Text>
        //     <Text size="xs" weight={300}>
        //     Minimum Return
        //     </Text>
        //   </>);
        // }

        return;
      }
      case 'withdraw_liquidity':{
        setTxType('Withdraw Liquidity');
        setFrAmount(from_token_amount);

        const refundAssetsLog = findWasmLog(tx.arrayLog, 'refund_assets');
        if (!refundAssetsLog) throw 'Failed to find liquidity refund logs';
        const refundAssets = refundAssetsLog.split(',');

        const assets = [];
        // refundAssets.forEach(asset=> {
        for (let i=0; i < refundAssets.length; i++){
          const asset = refundAssets[i];
          
          const index = asset.indexOf('secret1');
          const result = [asset.substring(0, index), asset.substring(index)];

          const asset_token_info = await getTokenInfo(result[1].trim());

          assets.push({
            ...asset_token_info,
            amount: parseInt(result[0].trim()),
            contract: result[1].trim(),
          });
        }
        const asset0_amount = parseInt(assets[0].amount) / Math.pow(10,assets[0].decimals);
        const asset1_amount = parseInt(assets[1].amount) / Math.pow(10,assets[1].decimals);
        const asset0_string = `${asset0_amount} ${assets[0].symbol}`;
        const asset1_string = `${asset1_amount} ${assets[1].symbol}`;

        setTo(<>
          <Text size="sm" weight={500}>
            SecretSwap Pair&nbsp;&nbsp;&nbsp;&nbsp;{`${assets[0].symbol} <---> ${assets[1].symbol}`}
          </Text>
          <Text size="sm" weight={300}>
            {`Swap Contract: ${sendMsg.recipient}`}
          </Text>
          <Text size="sm" weight={300}>
            {`LP Token: ${executedContract}`}
          </Text>
        </>);

        setToAmount(<>
          <Text size="sm" weight={500}>
            {asset0_string}
          </Text>
          <Text size="sm" weight={500}>
            {asset1_string}
          </Text>
        </>);

        // const exactAmount = (tx.arrayLog || []).find(log=> log.type==='wasm' && log.key==='return_amount')?.value;

        return;
      }
      default:
        break;
    }
  };

  const handleSecretswapPool = async(pool: any, handleMsg: any) => {
    setTxApp('SecretSwap');

    const handle = Object.keys(handleMsg)[0];
    switch(handle){
      case 'deposit': {
        setTxType('Stake');
        const {send: sendMsg}: Snip20SendOptions = txMsg.msg;

        const from_token_info = await getTokenInfo(executedContract);
        const from_token_amount = `${parseInt(sendMsg.amount) / Math.pow(10,from_token_info.decimals)} ${from_token_info.symbol}`;

        const to_token_info = await getTokenInfo(sendMsg.recipient);

        setFrAmount(from_token_amount);
        setTo(<>
          <Text size="sm" weight={500}>
            {sendMsg.recipient === INFINITY_POOL_CONTRACT ? 'SecretSwap Infinity Pool' : `SecretSwap ${from_token_info.symbol} Staking`}
          </Text>
          <Text size="xs" weight={300}>
            Pool Contract: {sendMsg.recipient}
          </Text>
          <Text size="xs" weight={300}>
            Token Contract: {pool.inc_token.address}
          </Text>
        </>);
        setToAmount(`${to_token_info.symbol}`);
        return;
      }
      case 'redeem': {
        const redeemAmount = parseInt(handleMsg.redeem.amount);
        const reward_token_info = pool.rewards_token;

        const to_token_info = pool.inc_token;
        const to_amount_string = `${redeemAmount / Math.pow(10,to_token_info.decimals)} ${to_token_info.symbol}`;
        if (redeemAmount) {
          setTxType('Unstake');
          const to_token_info = pool.inc_token;
          const to_amount_string = `${redeemAmount / Math.pow(10,to_token_info.decimals)} ${to_token_info.symbol}`;
          setToAmount(to_amount_string);
        } else {
          setTxType('Claim Rewards');
          
          setToAmount(`${reward_token_info.symbol}`);
        }
      


        setTo(<>
          <Text size="sm" weight={500}>
            {`SecretSwap ${to_token_info.symbol} Staking`}
          </Text>
          <Text size="xs" weight={300}>
            Pool Contract: {executedContract}
          </Text>
          <Text size="xs" weight={300}>
            Token Contract: {to_token_info.address}
          </Text>
        </>);
        return;
      }
      default:
        break;
    }
  };

  const handleSecretswapSend = async(handleMsg: any) => {
    setTxApp('SecretSwap');
    const sendMsg: Snip20SendOptions = txMsg.msg;

    const from_token = executedContract;
    const from_token_info = await getTokenInfo(executedContract);

    const from_token_amount = `${parseInt(sendMsg.send.amount) / Math.pow(10,from_token_info.decimals)} ${from_token_info.symbol}`;
    const handle = Object.keys(handleMsg)[0];
    switch(handle){
      case 'swap': {
        setTxType('Swap');
        setFrAmount(from_token_amount);

        const otherToken = findOtherToken(sendMsg.send.recipient, from_token);
        if (!otherToken?.token) throw 'Failed to find other token of swap pool';
        const to_token_info = await getTokenInfo(otherToken.token.contract_addr);
        // setTo(<>
        //   <Text size="sm" weight={500}>
        //     {to_token_info.name}
        //   </Text>
        //   <Text size="sm" weight={500}>
        //     {otherToken.token.contract_addr}
        //   </Text>
        // </>);
        setTo(<>
          <Text size="sm" weight={500}>
            SecretSwap Pair&nbsp;&nbsp;&nbsp;&nbsp;{`${from_token_info.symbol} <---> ${to_token_info.symbol}`}
          </Text>
          <Text size="sm" weight={300}>
            {`Swap Contract: ${sendMsg.send.recipient}`}
          </Text>
          <Text size="sm" weight={300}>
            {`${from_token_info.symbol} Contract: ${executedContract}`}
          </Text>
          <Text size="sm" weight={300}>
            {`${to_token_info.symbol} Contract: ${otherToken.token.contract_addr}`}
          </Text>
        </>);

        const estAmountStr = `~${parseInt(handleMsg.swap.expected_return) / Math.pow(10,to_token_info.decimals)} ${to_token_info.symbol}`;
        const exactAmount = (tx.arrayLog || []).find(log=> log.type==='wasm' && log.key==='return_amount')?.value;

        if (exactAmount) {
          setToAmount(`${parseInt(exactAmount) / Math.pow(10,to_token_info.decimals)} ${to_token_info.symbol}`);
        } else {
          setToAmount(<>
            <Text size="sm" weight={500}>
              {estAmountStr}
            </Text>
            <Text size="xs" weight={300}>
            Minimum Return
            </Text>
          </>);
        }

        return;
      }
      case 'to': {
        // Router swap
        setTxType('Swap');
        setFrAmount(from_token_amount);

        let token = from_token;
        const history = [from_token_info.symbol];

        for (let i=0; i < handleMsg.hops.length; i++){
          const hop = handleMsg.hops[i];
          const otherToken = findOtherToken(hop.pair_address, token);
          token = otherToken?.token?.contract_addr;

          const token_info = await getTokenInfo(token);
          history.push(token_info.symbol);
        }

        if (!token || token === from_token) throw `Failed to find destination token of router swap at ${tx.height}`;
        const to_token_info = await getTokenInfo(token);

        setTo(<>
          <Text size="sm" weight={500}>
            SecretSwap Router Swap&nbsp;&nbsp;&nbsp;&nbsp;{`${history.join(' --> ')}`}
          </Text>
          <Text size="sm" weight={300}>
            {`Swap Contract: ${sendMsg.send.recipient}`}
          </Text>
          <Text size="sm" weight={300}>
            {`${from_token_info.symbol} Contract: ${executedContract}`}
          </Text>
          <Text size="sm" weight={300}>
            {`${to_token_info.symbol} Contract: ${token}`}
          </Text>
        </>);

        const estAmountStr = `~${parseInt(handleMsg.expected_return) / Math.pow(10,to_token_info.decimals)} ${to_token_info.symbol}`;

        setToAmount(<>
          <Text size="sm" weight={500}>
            {estAmountStr}
          </Text>
          <Text size="xs" weight={300}>
            Minimum Return
          </Text>
        </>);
        // const exactAmount = (tx.arrayLog || []).find(log=> log.type==='wasm' && log.key==='return_amount')?.value;

        // if (exactAmount) {
        //   setToAmount(`${parseInt(exactAmount) / Math.pow(10,to_token_info.decimals)} ${to_token_info.symbol}`);
        // } else {
        //   setToAmount(<>
        //     <Text size="sm" weight={500}>
        //       {estAmountStr}
        //     </Text>
        //     <Text size="xs" weight={300}>
        //     Minimum Return
        //     </Text>
        //   </>);
        // }

        return;
      }
      case 'withdraw_liquidity':{
        setTxType('Withdraw Liquidity');
        setFrAmount(from_token_amount);

        const refundAssetsLog = findWasmLog(tx.arrayLog, 'refund_assets');
        if (!refundAssetsLog) throw 'Failed to find liquidity refund logs';
        const refundAssets = refundAssetsLog.split(',');

        const assets = [];
        // refundAssets.forEach(asset=> {
        for (let i=0; i < refundAssets.length; i++){
          const asset = refundAssets[i];
          
          const index = asset.indexOf('secret1');
          const result = [asset.substring(0, index), asset.substring(index)];

          const asset_token_info = await getTokenInfo(result[1].trim());

          assets.push({
            ...asset_token_info,
            amount: parseInt(result[0].trim()),
            contract: result[1].trim(),
          });
        }
        const asset0_amount = parseInt(assets[0].amount) / Math.pow(10,assets[0].decimals);
        const asset1_amount = parseInt(assets[1].amount) / Math.pow(10,assets[1].decimals);
        const asset0_string = `${asset0_amount} ${assets[0].symbol}`;
        const asset1_string = `${asset1_amount} ${assets[1].symbol}`;

        setTo(<>
          <Text size="sm" weight={500}>
            SecretSwap Pair&nbsp;&nbsp;&nbsp;&nbsp;{`${assets[0].symbol} <---> ${assets[1].symbol}`}
          </Text>
          <Text size="sm" weight={300}>
            {`Swap Contract: ${sendMsg.send.recipient}`}
          </Text>
          <Text size="sm" weight={300}>
            {`LP Token: ${executedContract}`}
          </Text>
        </>);

        setToAmount(<>
          <Text size="sm" weight={500}>
            {asset0_string}
          </Text>
          <Text size="sm" weight={500}>
            {asset1_string}
          </Text>
        </>);

        // const exactAmount = (tx.arrayLog || []).find(log=> log.type==='wasm' && log.key==='return_amount')?.value;

        return;
      }
      default:
        break;
    }
  };

  const handleSecretswapOther = async(pair: any) => {
    setTxApp('SecretSwap');

    switch(executedHandle){
      case 'provide_liquidity':{
        setTxType('Provide Liquidity');

        const asset0 = txMsg.msg.provide_liquidity.assets[0];
        const asset1 = txMsg.msg.provide_liquidity.assets[1];

        const asset0_token_info = await getTokenInfo(asset0.info.token.contract_addr);
        const asset1_token_info = await getTokenInfo(asset1.info.token.contract_addr);

        const asset0_amount = parseInt(asset0.amount) / Math.pow(10,asset0_token_info.decimals);
        const asset1_amount = parseInt(asset1.amount) / Math.pow(10,asset1_token_info.decimals);
        const asset0_string = `${asset0_amount} ${asset0_token_info.symbol}`;
        const asset1_string = `${asset1_amount} ${asset1_token_info.symbol}`;

        

        const returnLog = findWasmLog(tx.arrayLog, 'share');
        if (returnLog) {
          const lp_token_info = await getTokenInfo(pair.liquidity_token);
          
          const shareAmount = parseInt(returnLog) / Math.pow(10,lp_token_info.decimals);
          setToAmount(`${shareAmount} ${lp_token_info.symbol}`);
        }

        setFrAmount(<>
          <Text size="sm" weight={500}>
            {asset0_string}
          </Text>
          <Text size="sm" weight={500}>
            {asset1_string}
          </Text>
        </>);

        setTo(<>
          <Text size="sm" weight={500}>
            SecretSwap Pair&nbsp;&nbsp;&nbsp;&nbsp;{`${asset0_token_info.symbol} <---> ${asset1_token_info.symbol}`}
          </Text>
          <Text size="sm" weight={300}>
            {`Swap Contract: ${executedContract}`}
          </Text>
          <Text size="sm" weight={300}>
            {`LP Token: ${pair.liquidity_token}`}
          </Text>
        </>);
        return;
      }
      default:
        break;
    }
  };

  const handleSnip20Deposit = async() => {
    const token_info = await getTokenInfo(executedContract);
    const {rate, denom} = await getExchangeRate(executedContract);

    //@ts-ignore
    const sentFunds: Coin[] = tx.tx.body?.messages[0].sent_funds;

    let fromAmount = 0;
    let fromSymbol = denom;
    let toAmount = 0;
    token_info.symbol;
    if (sentFunds.length) {
      const sentAmount = sentFunds.find((coin: Coin) => coin.denom === denom)?.amount;
      if (sentAmount) fromAmount = parseInt(sentAmount);
      toAmount = fromAmount * rate / Math.pow(10,token_info.decimals);
    }
    if (denom === 'uscrt') {
      fromAmount = fromAmount  / Math.pow(10,6);
      fromSymbol = 'SCRT';
    }



    // const amount = data.amount / Math.pow(10,token_info.decimals);
    const fromString = fromAmount + ` ${fromSymbol}`;
    const toString = toAmount + ` ${token_info.symbol}`;
    setTxType('Wrap');

    // setFrom('You');
    // setTo(data.recipient);
    setFrAmount(fromString);
    setToAmount(toString);
  };

  const handleCloakDeposit = async() => {
    setTxApp('BlackBox');
    const sendMsg: Snip20SendOptions = txMsg.msg;
    // const amount = data.amount / Math.pow(10,6);
    const amount = parseInt(sendMsg.send.amount) / Math.pow(10,6);
    const amountOut = (parseInt(sendMsg.send.amount) - 1000000) / Math.pow(10,6);
    const fromString = amount + ' sSCRT';
    const toString = amountOut + ' SCRT';
    const code = tx.jsonLog ? (tx.jsonLog[0].events.find(event => event.type === 'wasm')?.attributes || []).find(attribute => attribute.key === 'tx_code')?.value : undefined;
    setFrAmount(fromString);
    setToAmount(toString);

    setTo(<>
      <Text size="sm" weight={500}>
        Blackbox Cloak Deposit
      </Text>
      <Text size="sm" weight={500}>
        Claim Code: {code}
      </Text>
    </>);

    if (code) {
      const { exists } = await querierJs.query.compute.queryContract({
        contract_address: CLOAK_ADDRESS,
        query: {
          get_exists: { tx_key: code }
        }
      }) as any;
      setToAmount(<>
        <Text size="sm" weight={500}>
          {toString}
        </Text>
        <Text size="sm" weight={500} color={exists ? 'green' : undefined}>
          {exists ? 'Available' : 'Claimed'}
        </Text>
      </>);
    }



    // // const amount = data.amount / Math.pow(10,token_info.decimals);
    // const fromString = fromAmount + ` ${fromSymbol}`;
    // const toString = toAmount + ` ${token_info.symbol}`;
    // setTxType('Wrap');

    // // setFrom('You');
    // // setTo(data.recipient);

  };

  return <TxRow tx={tx} txType={txType} app={txApp} from={from} fromAmount={frAmount} to={to} toAmount={toAmount} showFullTx={!hideJson} />;

};

export default ComputeRow;