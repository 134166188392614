import { ActionIcon, Group, Text } from "@mantine/core";
import { useState } from "react";

import {
  IconEye,
  IconEyeOff,
} from '@tabler/icons';

export default function HiddenText({text, hidden=true}: {text: string, hidden?: boolean}) {

  const [hide, setHide] = useState(hidden);

  const toReturn = hide ? '-'.repeat(text.length) : text;

  return (
    // <Group spacing="sm">
    <div style={{display: 'flex', alignItems: 'center'}}>
      <Text size="sm" style={{fontFamily: 'Courier'}}>{toReturn}</Text>
      &nbsp;&nbsp;
      <ActionIcon variant="subtle" onClick={()=>setHide(!hide)}>
        {
          hide ? 
            <IconEye size={18} />
            :
            <IconEyeOff size={18} />
        }
      </ActionIcon>
    </div>
    // </Group>
  )
}