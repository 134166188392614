import { DashboardNav } from '../Components/DashboardNav';
import { Outlet, Route, Routes } from 'react-router-dom';
import Home from './home';
import TransactionsPage from './transactions';

export default function Dashboard() {

  return (
    <>
      <DashboardNav />
      <Outlet />
    </>
  );
}