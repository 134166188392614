import secretswap_pairs from './secretswap_pairs.json';
import secretswap_rewards from './secretswap_pools.json';

export const secretswapPairs = secretswap_pairs.pairs;
export const secretswapRewards = secretswap_rewards.pools;

export const findSecretswapPair = (address: string) => {
  return secretswap_pairs.pairs.find(pair=>pair.contract_addr === address);
};

export const findSecretSwapPool = (pool_address: string) => {
  return secretswapRewards.find(pool => pool.pool_address === pool_address);
};

export const findSecretSwapPoolByToken = (token_address: string) => {
  return secretswapRewards.find(pool => pool.inc_token.address === token_address);
};

export const findOtherToken = (address: string, token_in: string) => {
  const pair = secretswap_pairs.pairs.find(pair=>pair.contract_addr === address);
  const asset = pair?.asset_infos.filter(asset=>asset.token?.contract_addr !== token_in);
  if (asset && asset.length) return asset[0];
  else return undefined;
};

export const INFINITY_POOL_CONTRACT = 'secret1t32sqak9mr844p0vwhmt4c23nsw8t03vexk4xv';
// export const SEFI_STAKING_V2_CONTRACT = 'secret1knars62aly28xkqxe8xeqtf7ans8hqxgm6a05k';
// export const SEFI_STAKING_V1_CONTRACT = 'secret1y9z3ck449a46r4ku7klkhdxnlq07zh4shc7cuy';

export const SECRETSWAP_ROUTER_CONTRACT = 'secret1xy5r5j4zp0v5fzza5r9yhmv7nux06rfp2yfuuv';

export const INFINITY_POOL = {
  pool_address: INFINITY_POOL_CONTRACT,
  inc_token: {
    symbol: 'SEFI',
    address: 'secret15l9cqgz5uezgydrglaak5ahfac69kmx2qpd6xt',
    decimals: 6,
    name:	'Secret Finance Token',
  },
  rewards_token: {
    symbol: 'SEFI',
    address: 'secret15l9cqgz5uezgydrglaak5ahfac69kmx2qpd6xt',
    decimals: 6,
    name:	'Secret Finance Token',
  }
}