/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { createStyles, Table, Checkbox, ScrollArea, Group, Avatar, Text, Accordion } from '@mantine/core';
import { TxResponse } from 'secretjs';
import { Coin } from 'secretjs/dist/protobuf/cosmos/base/v1beta1/coin';
import { querierJs } from '../../Utils/querier';
import { ContractInfo } from 'secretjs/dist/grpc_gateway/secret/compute/v1beta1/types.pb';
import ComputeRow from '../Rows/Compute';
import { TxRow } from '../Rows/Row';
interface TXTableProps {
    data: TxResponse[];
    address: string;
}

interface RowProps {
  tx: TxResponse;
  address: string;
}

const BankRow = ({tx, address}: RowProps) => {
  //@ts-ignore
  const moduleName = tx.jsonLog[0].events.find(e=> e.type==='message')?.attributes.find(a=>a.key === 'module')?.value;

  //@ts-ignore
  const msg: any = tx.tx.body?.messages[0];
  const msgType = msg['@type'];

  let from, frAmount, to, toAmount: any;

  switch(msgType) {
    case '/cosmos.bank.v1beta1.MsgSend':
      const amountUscrt = msg.amount.find((coin: Coin)=>coin.denom==='uscrt').amount;
      const amountString = (parseInt(amountUscrt) / 10e5).toString() + ' SCRT';
      frAmount = amountString;
      toAmount = amountString;
      if (msg.from_address === address) {
        from = 'You';
        to = msg.to_address;
      }
      break;
    default:
      return <></>;
      break;
  }

  return <TxRow
    tx={tx}
    txType={'Send'}
    app=''
    from={from || ''}
    fromAmount={frAmount}
    to={to}
    toAmount={toAmount}
  />
  // return (
  //   <tr key={tx.transactionHash}>
  //     <td>
  //       <Group spacing="sm">
  //         <Text size="sm" weight={500}>
  //           {tx.height}
  //         </Text>
  //         <Text size="sm" weight={500}>
  //           {tx.timestamp}
  //         </Text>
  //       </Group>
  //     </td>
  //     <td>
  //       <Group spacing="sm">
  //         {/* <Avatar size={26} src={item.avatar} radius={26} /> */}
  //         <Text size="sm" weight={500}>
  //           Send
  //         </Text>
  //       </Group>
  //     </td>
  //     <td></td>
  //     <td>{from}</td>
      
  //     <td>{frAmount}</td>
      
  //     <td>{to}</td>
      
  //     <td>{toAmount}</td>
  //     {/* <td><a href={`https://secretnodes.com/secret/transactions/${tx.transactionHash}`} target="_blank" rel="noreferrer noopener">View</a></td> */}
  //   </tr>
  // );
};

const OtherRow = ({tx}: {tx: TxResponse}) => {
  //@ts-ignore
  const moduleName = tx.jsonLog[0].events.find(e=> e.type==='message')?.attributes.find(a=>a.key === 'module')?.value;

  //@ts-ignore
  const msg = tx.tx.body?.messages[0];

  return (
    <tr key={tx.transactionHash}>
      <td>
        <Group spacing="sm">
          <Text size="sm" weight={500}>
            {tx.height}
          </Text>
          <Text size="sm" weight={500}>
            {tx.timestamp}
          </Text>
        </Group>
      </td>
      <td>
        <Group spacing="sm">
          {/* <Avatar size={26} src={item.avatar} radius={26} /> */}
          <Text size="sm" weight={500}>
            {moduleName}
          </Text>
        </Group>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      {/* <td><a href={`https://secretnodes.com/secret/transactions/${tx.transactionHash}`} target="_blank" rel="noreferrer noopener">View</a></td> */}
    </tr>
  );
};

export function TXTable({ data, address }: TXTableProps) {

  const rows = data.map((item) => {
    try {
      if (!item) return;
      if (!item.jsonLog?.length) console.log(item);
      //@ts-ignore
      const moduleName = item.code ? item.codespace : item.jsonLog[0].events.find(e=> e.type==='message')?.attributes.find(a=>a.key === 'module')?.value;

      switch(moduleName) {
        case 'bank':
          return (<BankRow tx={item} address={address} />);
          break;
        case 'compute':
          return (<ComputeRow tx={item} address={address} />);
          break;
        default:
          return (<OtherRow tx={item} />);
          break;
      }
    } catch (err: any){
      console.error(err, item);
    }
  });

  return (
    // <ScrollArea>
    <Accordion variant="filled">
      <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
        <thead>
          <tr>
            <th></th>
            <th>Type</th>
            <th>App</th>
            <th>From</th>
            <th>Amount--</th>
            <th>To</th>
            <th>Amount++</th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </Accordion>
    // </ScrollArea>
  );
}