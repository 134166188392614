import { ArrayLog, SecretNetworkClient } from "secretjs";

export const querierJs = new SecretNetworkClient({
  url: 'https://secret-4.api.trivium.network:1317',
  chainId: 'secret-4',
});

export const getTokenInfo = async (contract_address: string) => {
  const key = `token_info-${contract_address}`;

  const fromStorage = localStorage.getItem(key);
  if (fromStorage) return JSON.parse(fromStorage);

  const {token_info} = await querierJs.query.compute.queryContract({
    contract_address,
    query: {
      token_info: {}
    }
  }) as any;

  localStorage.setItem(key, JSON.stringify(token_info));
  return token_info;
};

export const getExchangeRate = async (contract_address: string) => {
  const key = `exchange_rate-${contract_address}`;

  const fromStorage = localStorage.getItem(key);
  if (fromStorage) return JSON.parse(fromStorage);

  const {exchange_rate} = await querierJs.query.compute.queryContract({
    contract_address,
    query: {
      exchange_rate: {}
    }
  }) as {exchange_rate: {rate: number, denom: string}};

  localStorage.setItem(key, JSON.stringify(exchange_rate));
  return exchange_rate;
};

export const getContractInfo = async (contract_address: string) => {
  const key = `contract_info-${contract_address}`;

  const fromStorage = localStorage.getItem(key);
  if (fromStorage) return JSON.parse(fromStorage);

  const { ContractInfo: contract_info } = await querierJs.query.compute.contractInfo({
    contract_address,
  });

  localStorage.setItem(key, JSON.stringify(contract_info));
  return contract_info;
};

export const getSiennaPairInfo = async (contract_address: string) => {
  const key = `sienna_pair_info-${contract_address}`;

  const fromStorage = localStorage.getItem(key);
  if (fromStorage) return JSON.parse(fromStorage);

  const {pair_info} = await querierJs.query.compute.queryContract({
    contract_address,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    query: 'pair_info', // whyy sienna :(
  }) as any;

  localStorage.setItem(key, JSON.stringify(pair_info));
  return pair_info;
};

export const findWasmLog = (arrayLog: ArrayLog = [], key: string) => {
  return (arrayLog || []).find(log=> log.type==='wasm' && log.key===key)?.value;
}

export const findLog = (arrayLog: ArrayLog = [], type: string, key: string) => {
  return (arrayLog || []).find(log=> log.type===type && log.key===key)?.value;
}